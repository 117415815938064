
import { StudentQuizAttemptModel } from "@/models";
import { routeNames } from "@/router/routeNames";
import { computed, defineComponent, PropType } from "vue";
import constants from "@/components/quiz/constants";

const __default__ = defineComponent({
    props: {
        id: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        progresses: {
            type: Array as PropType<StudentQuizAttemptModel[]>,
            required: false,
            default: () => []
        },
        themeColorHex: {
            type: String,
            required: true
        },
        pointsPossible: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const quizRouteName = routeNames.quiz;

        const studentQuizAttempt = computed(() =>
            props.progresses.find((q) => q.quizId === props.id)
        );

        const displayStatus = computed(() => {
            if (studentQuizAttempt.value?.points) {
                const score =
                    ((studentQuizAttempt.value?.points || 0) /
                        (props.pointsPossible || 1)) *
                    100;

                if (studentQuizAttempt.value?.hasPerfectScore) {
                    return {
                        color: constants.QUIZ_SCORE_FONT_COLOR_GREEN,
                        text: `Perfect! (${studentQuizAttempt.value?.points}/${props.pointsPossible})`
                    };
                } else {
                    if (score >= constants.QUIZ_SCORE_THRESHOLD_UPPER) {
                        return {
                            color: constants.QUIZ_SCORE_FONT_COLOR_GREEN,
                            text: `Passed (${studentQuizAttempt.value?.points}/${props.pointsPossible})`
                        };
                    } else if (score >= constants.QUIZ_SCORE_THRESHOLD_LOWER) {
                        return {
                            color: constants.QUIZ_SCORE_FONT_COLOR_YELLOW,
                            text: `Passed (${studentQuizAttempt.value?.points}/${props.pointsPossible})`
                        };
                    }
                    return {
                        color: constants.QUIZ_SCORE_FONT_COLOR_RED,
                        text: `Good Try! (${studentQuizAttempt.value?.points}/${props.pointsPossible})`
                    };
                }
            } else {
                return {
                    color: constants.QUIZ_SCORE_FONT_COLOR_GRAY,
                    text: `${props.pointsPossible} Points Possible`
                };
            }
        });

        return {
            quizRouteName,
            displayStatus,
            studentQuizAttempt
        };
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "9489394a": (_ctx.themeColorHex)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__