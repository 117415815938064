const QUIZ_SCORE_THRESHOLD_PERFECT = 100;
const QUIZ_SCORE_THRESHOLD_UPPER = 85;
const QUIZ_SCORE_THRESHOLD_LOWER = 70;
const QUIZ_SCORE_FONT_COLOR_GREEN = "#71bf44";
const QUIZ_SCORE_FONT_COLOR_YELLOW = "#fec442";
const QUIZ_SCORE_FONT_COLOR_RED = "#eb0a11";
const QUIZ_SCORE_FONT_COLOR_GRAY = "#cfcfcf";


export default {
    QUIZ_SCORE_THRESHOLD_UPPER, QUIZ_SCORE_THRESHOLD_LOWER,
    QUIZ_SCORE_FONT_COLOR_GREEN, QUIZ_SCORE_FONT_COLOR_YELLOW,
    QUIZ_SCORE_FONT_COLOR_RED, QUIZ_SCORE_FONT_COLOR_GRAY,
    QUIZ_SCORE_THRESHOLD_PERFECT
};
