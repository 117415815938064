
import { defineComponent, onMounted, PropType, ref } from "vue";
import Flipbook from "flipbook-vue";

import {
    FlipbookElement,
    StudentDigitalBookPageModel,
    StudentLessonDigitalBookPageModel,
    UserModel
} from "@/models";

import { useStore, AppActionTypes } from "@/store";

export default defineComponent({
    name: "flip-book",
    components: {
        flipbook: Flipbook
    },
    props: {
        pages: {
            type: Array as PropType<StudentLessonDigitalBookPageModel[]>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        user: {
            type: Object as PropType<UserModel>,
            required: true
        },
        progress: {
            type: Object as PropType<StudentDigitalBookPageModel>,
            required: true
        }
    },
    setup(props) {
        const store = useStore();

        const bookRef = ref<FlipbookElement | null>(null);
        const fullscreenBookRef = ref<FlipbookElement | null>(null);

        const overlayRef = ref<HTMLElement | null>(null);
        const isFullScreen = ref(false);

        // Extract imageUrls from pages
        const urls = props.pages
            .map((x) => x.imageUrl)
            .filter((imageUrl): imageUrl is string => !!imageUrl);

        // Insert null at position 0 to indicate the cover should be single page (See: https://github.com/ts1/flipbook-vue#pages)
        const pageUrls = [null, ...urls];

        /**
         * Toggle isFullScreen
         * If isFullscreen, show overlay, else, hide overlay
         * Sync pages between fullscreen flipbook and lesson view flipbook
         */
        const handleFullScreenToggle = (): void => {
            if (!overlayRef.value?.style) {
                return;
            }

            // Toggle fullscreen
            isFullScreen.value = !isFullScreen.value;
            overlayRef.value.style.width = isFullScreen.value ? "100%" : "0%";

            // Get page number from book that was being viewed
            const currentInternalPageNumber = isFullScreen.value
                ? bookRef.value?.page
                : fullscreenBookRef.value?.page;

            if (!currentInternalPageNumber) {
                return;
            }

            // Go to page number in book now being viewed
            isFullScreen.value
                ? fullscreenBookRef.value?.goToPage(currentInternalPageNumber)
                : bookRef.value?.goToPage(currentInternalPageNumber);
        };

        /**
         * Get current page number from student progress
         * @returns {number} - The current page number
         */
        const getCurrentPageNumber = (): number => {
            if (props.user.role !== "Student" || props.progress.isComplete) {
                return 0;
            }

            /**
             * Get saved current page by ID (if there is one)
             * If a saved page is not present, findIndex will return -1
             * Page index will need to be incremented by one to account for null entry at position zero
             */
            const savedPageIndex = props.pages.findIndex(
                (p) => p.id === props.progress.lessonDigitalBookPageId
            );
            let pageNumber = savedPageIndex === -1 ? 0 : savedPageIndex + 1;

            /**
             * If the page number is not even, this means they left off on the right side of the book
             * (This is only possible on mobile devices in single page mode)
             * If so, flip back one page
             */
            if (pageNumber && pageNumber % 2 !== 0) {
                pageNumber--;
            }

            return pageNumber;
        };

        /**
         * Sync book progress
         * Called on page flip right/left events from the flipbooks
         * Preloads next images for next page to provide a smoother flip animation
         * Updates current book progress for a student if they have yet to finish the given book
         * @param {number} page - The current page number (1 indexed to account for null entry at position 0)
         */
        const syncBookProgress = (page: number): void => {
            bookRef.value?.preloadImages();

            if (props.progress.isComplete || props.user.role !== "Student") {
                return;
            }

            const currentPage = props.pages[page - 1];

            store.dispatch(AppActionTypes.createStudentDigitalBook, {
                model: {
                    lessonId: props.lessonId,
                    lessonDigitalBookPageId: currentPage.id
                },
                courseId: props.courseId,
                lessonId: props.lessonId
            });
        };

        const handlePageFlip = (page: number): void => {
            syncBookProgress(page);
        };

        const handlePageFlipFullscreen = (page: number): void => {
            syncBookProgress(page);
        };

        onMounted(() => {
            const currentPageNumber = getCurrentPageNumber();

            if (currentPageNumber) {
                bookRef.value?.goToPage(currentPageNumber);
            }
        });

        return {
            bookRef,
            handleFullScreenToggle,
            handlePageFlip,
            handlePageFlipFullscreen,
            isFullScreen,
            pageUrls,
            overlayRef,
            fullscreenBookRef
        };
    }
});
