import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  id: "video-name",
  class: "mb-3"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xl-8 offset-xl-2" }
const _hoisted_5 = { ref: "player" }
const _hoisted_6 = {
  id: "video-description",
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.player)
      ? (_openBlock(), _createBlock(_component_loading_indicator, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.video.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, null, 512),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.videoDescription), 1)
      ])
    ])
  ]))
}