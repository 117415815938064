
import { computed, defineComponent, ref, watch, onMounted } from "vue";
import { AppActionTypes, AppMutationTypes, useStore } from "@/store";

import { CourseItemModel, LessonModel } from "@/models";
import { COLOR_DEFAULT, loadUserDependentData } from "@/helpers";

import { routeNames } from "@/router/routeNames";
import { routeHashes } from "@/router/routeHashes";
import { useRoute } from "vue-router";

import LessonDigitalBookVue from "@/components/lesson/LessonDigitalBook.vue";
import LessonVideoVue from "@/components/lesson/LessonVideo.vue";
import QuizCardVue from "@/components/lesson/QuizCard.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";

export default defineComponent({
    components: {
        "lesson-video": LessonVideoVue,
        "lesson-digital-book": LessonDigitalBookVue,
        "quiz-card": QuizCardVue,
        "page-greeting": PageGreetingVue
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        tab: {
            type: String,
            required: false,
            default: "video"
        }
    },

    setup(props) {
        const store = useStore();
        const { getters, state, dispatch } = store;
        const route = useRoute();

        const course = computed<CourseItemModel | null>(() =>
            getters.getCourseItem(props.courseId)
        );
        const lesson = computed<LessonModel | null>(() =>
            getters.getLesson(props.lessonId)
        );
        const progress = computed(() => lesson.value?.lessonProgress);

        const loadingStudentData = ref<boolean>(true);

        const isParent = computed(() =>
            store.state.user ? store.state.user.role === "Parent" : false
        );
        // Lesson activities
        const video = computed(() => lesson.value?.video);
        const hasVideo = computed(() => lesson.value?.hasVideo ?? false);

        const bookPages = computed(() => lesson.value?.bookPages ?? []);
        const hasBook = computed(() => lesson.value?.hasBook ?? false);

        const quizzes = computed(() => lesson.value?.quizzes ?? []);
        const hasQuiz = computed(() => lesson.value?.hasQuiz ?? false);

        // Page greeting
        const title = ref<string>("");
        const message = "Earn stickers and level up by completing activities!";
        const themeColorHex = ref<string>(COLOR_DEFAULT);

        // Selected tab
        type TabType = "video" | "book";
        const selectedTab = ref<TabType>(props.tab as TabType);

        // Assure a valid tab is set
        watch(
            () => lesson.value?.id,
            () => {
                if (selectedTab.value === "book" && !hasBook.value) {
                    selectedTab.value = "video";
                }

                if (selectedTab.value === "video" && !hasVideo.value) {
                    selectedTab.value = "book";
                }
            },
            {
                immediate: true // Run this watch immedately on loaded so an appropriate tab gets set.
            }
        );

        const scrollToActivity = (): void => {
            if (route.hash === routeHashes.quiz) {
                const element = document.getElementById("page-content");

                if (element) {
                    element.scrollTop =
                        element.scrollHeight - element.clientHeight;
                }
            }

            if (route.hash === routeHashes.eBook) {
                selectedTab.value = "book";
            }

            if (route.hash === routeHashes.video) {
                selectedTab.value = "video";
            }
        };

        // Navigate to specified section
        onMounted(() => {
            if (!loadingStudentData.value) {
                scrollToActivity();
            } else {
                watch(
                    () => loadingStudentData.value,
                    () => setTimeout(scrollToActivity, 200)
                );
            }
        });

        // Get lesson progress
        loadUserDependentData(store, async () => {
            if (!lesson.value) {
                await dispatch(AppActionTypes.getLesson, props.lessonId);

                if (!lesson.value) {
                    return;
                }
            }

            if (!course.value) {
                await dispatch(AppActionTypes.getCourseItems);

                if (!course.value) {
                    return;
                }
            }

            if (course.value.courseColorHex) {
                themeColorHex.value = course.value?.courseColorHex;
            }

            if (course.value?.logoImageUrl) {
                store.commit(
                    AppMutationTypes.setPageImage,
                    course.value.logoImageUrl
                );
            }

            if (lesson.value.name) {
                title.value = `${lesson.value.name}`;
                store.commit(AppMutationTypes.setPageTitle, lesson.value.name);
            }

            loadingStudentData.value = false;
        });

        return {
            selectedTab,
            lesson,
            progress,
            bookPages,
            video,
            hasVideo,
            hasBook,
            hasQuiz,
            quizzes,
            quizRouteName: routeNames.quiz,
            loading: computed(() => getters.loading),
            loadingStudentData,
            user: computed(() => state.user),
            isStudent: computed(() => state.user?.role === "Student"),
            title,
            message,
            themeColorHex,
            isParent
        };
    }
});
