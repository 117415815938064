import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-717359c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 text-center" }
const _hoisted_2 = { class: "fa-stack fa-3x" }
const _hoisted_3 = {
  key: 0,
  class: "btn btn-bottom btn-perfect-score w-100 mt-2 mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, { "border-color-hex": _ctx.themeColorHex }, {
    footer: _withCtx(() => [
      (_ctx.studentQuizAttempt?.hasPerfectScore)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Perfect Score Achieved "))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "btn btn-bottom w-100 mt-2",
            to: {
                    name: _ctx.quizRouteName,
                    params: {
                        id: _ctx.id,
                        lessonId: _ctx.lessonId,
                        courseId: _ctx.courseId
                    }
                }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.studentQuizAttempt?.points ? "Try Again" : "Take the Quiz!"), 1)
            ]),
            _: 1
          }, 8, ["to"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle",
            class: "fa-thin fa-stack-2x text-warning"
          }),
          _createVNode(_component_font_awesome_icon, {
            icon: "trophy",
            class: "fa-stack-1x",
            style: _normalizeStyle({ color: _ctx.displayStatus.color })
          }, null, 8, ["style"])
        ]),
        _createElementVNode("p", {
          class: "mb-0",
          style: _normalizeStyle({ color: _ctx.displayStatus.color })
        }, _toDisplayString(_ctx.displayStatus.text), 5),
        _createElementVNode("h2", null, _toDisplayString(_ctx.name), 1)
      ])
    ]),
    _: 1
  }, 8, ["border-color-hex"]))
}